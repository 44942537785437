import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { splashTextAnimation, splashLogoAnimation } from '../components/Animations'

import symbol from '../assets/img/symbol.svg'

function Splash() {
  return (
    <div className="splash-screen">

      <motion.img
        key="splash-logo"
        className="logo splash splashLogoAnimation"
        variants={ splashLogoAnimation } 
        animate='show' 
        exit='hide'
        src={symbol} 
      />

      <motion.div
        key="splash-text"
        className="splash-text splashAnimation"
        variants={ splashTextAnimation } 
        animate='show' 
        exit='hide'
      >
        <h1 className="splash">Fii cool,<br/>întrerabă un<br/>specialist!</h1>
      </motion.div>

    </div>
  );
}

export default Splash;
