import React, { useState, useEffect } from 'react'

import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation,
  useNavigate
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { navbarAnimation, modalAnimation, quickFadeAnimation } from '../components/Animations'

import Programare from './Programare'
import { PopupButton } from "react-calendly";


import logo from '../assets/img/logo.svg';

const StaticNavigation = (props) => {

  const showModal = props.showModal
  console.log("props data:", props.showModal)

  useEffect(() => {
    if (showModal === true){
      setSeeCalendly(true)
    }
  }, [props]);



   // GET URL
  const url = window.location;
  // console.log('url:', url);

  // GET PATHNAME
  const path = window.location.pathname;
  // console.log('path:', path);

  const navigate = useNavigate()

  const [mainNav, setMainNav] = useState(true)
  const [iframeNav, setIframeNav] = useState(false)
  const [secondNav, setSecondNav] = useState(false)
  const [helpNav, setHelpNav] = useState(false)


  const [ isAbout, setIsAbout ] = useState(false);
  const [ isFaq, setIsFaq ] = useState(false);
  const [ isPrice, setIsPrice ] = useState(false);
  const [ isContact, setIsContact ] = useState(false);



// useEffect(() => {
//   if (openScheduleModal === true){
//     setSeeCalendly(true)
//   }
// }, []);

  useEffect(() => {
    if (path === '/'){
      setMainNav(true)
      setSecondNav(false)
    } else{
      setMainNav(false)
      setSecondNav(true)
    } 

    if (path === '/chat'){
      setMainNav(false)
      setSecondNav(false)
      setIframeNav(true)
    }  

    if (path === '/despre'){
      setMainNav(false)
      setSecondNav(true)
      setIsAbout(true)
    } 

    if (path === '/help'){
      setMainNav(false)
      setSecondNav(false)
      setHelpNav(true)
    } 

    if (path === '/intrebari-frecvente'){
      setMainNav(false)
      setSecondNav(true)
      setIsFaq(true)
    } 

    if (path === '/contact'){
      setIsContact(true)
    }

    if (path === '/onorariu'){
      setIsPrice(true)
    } 

    if (path === '/legal'){
      setMainNav(false)
      setSecondNav(true)
    }


  }, []); 

  const [seeCalendly, setSeeCalendly] = useState(false)

  function showCalendlyFunction(){
    setSeeCalendly(true)
  }
  function hideCalendlyFunction(){
    setSeeCalendly(false)
    props.hideModal(false)
  }

  return (
    <>
    <div className="static-navbar">
      <div className="nav">
        <Link to="/" className="nav-link"><div className="button home-btn"/></Link>

        <Link to="/despre" className={`nav-link ${!isAbout ? "" : "active"}`}>Despre</Link>

        <Link to="/intrebari-frecvente" className={`nav-link ${!isFaq ? "" : "active"}`}>Întrebări frecvente</Link>
        <Link to="/onorariu" className={`nav-link ${!isPrice ? "" : "active"}`}>Onorariu</Link>
        <Link to="/contact" className={`nav-link ${!isContact ? "" : "active"}`}>Contact</Link>
      </div>

      <button className="nav-button" onClick={showCalendlyFunction}>Programează-te!</button>
    </div>
    
      {seeCalendly ?
            <>
              {/*<button className="button close-modal-btn" onClick={hideCalendlyFunction}>Închide programarea</button> */}
              <button className="button close-modal-btn" onClick={hideCalendlyFunction}>Închide programarea</button> 
              
              <motion.div 
                className="modal modalAnimation"
                key="modal"
                variants={ modalAnimation } 
                animate='show' 
                exit='hide'
              >
                <Programare/>
              </motion.div>

              <motion.div 
                className="modal-overlay quickFadeAnimation"
                key="overaly"
                variants={ quickFadeAnimation } 
                animate='show' 
                exit='hide'
              >
                
               </motion.div>
            </>
      :null} 

    </>
  );
}

export default StaticNavigation;
