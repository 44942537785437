import React, { useState, useEffect } from 'react'

import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation,
  useNavigate
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { navbarAnimation, modalAnimation, quickFadeAnimation } from '../components/Animations'

import Programare from './Programare'

import logo from '../assets/img/logo.svg';

const Navigation = (props) => {

  const showModal = props.showModal
  // console.log("props data:", props.showModal)

  useEffect(() => {
    if (showModal === true){
      setSeeCalendly(true)
    }
  }, [props]);




   // GET URL
  const url = window.location;
  // console.log('url:', url);

  // GET PATHNAME
  const path = window.location.pathname;
  // console.log('path:', path);

  const navigate = useNavigate()

  const [mainNav, setMainNav] = useState(true)
  const [iframeNav, setIframeNav] = useState(false)
  const [secondNav, setSecondNav] = useState(false)
  const [helpNav, setHelpNav] = useState(false)


  useEffect(() => {
    if (path === '/'){
      setMainNav(true)
      setSecondNav(false)
    } else{
      setMainNav(false)
      setSecondNav(true)
    } 

    if (path === '/messenger'){
      setMainNav(false)
      setSecondNav(false)
      setIframeNav(true)
    }  

    if (path === '/despre'){
      setMainNav(false)
      setSecondNav(true)
    } 

    if (path === '/help'){
      setMainNav(false)
      setSecondNav(false)
      setHelpNav(true)
    } 

    if (path === '/intrebari-frecvente'){
      setMainNav(false)
      setSecondNav(true)
    } 

    if (path === '/legal'){
      setMainNav(false)
      setSecondNav(true)
    }


  }, []); 


  const [seeCalendly, setSeeCalendly] = useState(false)

  function showCalendlyFunction(){
    setSeeCalendly(true)
  }
  function hideCalendlyFunction(){
    setSeeCalendly(false)
    props.hideModal(false)
  }


  return (
    <>

    <div className="navbar">
        {iframeNav ?
          <>
            <img src={logo} className="logo nav"/>
          </>
        :null }

        {mainNav ?
          <>
            <Link to="/help"><img src={logo} className="logo nav"/></Link>
            <button className="button small-btn schedule" onClick={showCalendlyFunction}>
                    Programare
            </button>
            <Link to="help"><button className="button help-btn"/></Link>
          </>
        :null }

        {secondNav ?
          <>
            {/*<button onClick={() => navigate(-1)} className="button back-btn"/>*/}
            <Link to="/help"><button className="button back-btn"/></Link>
          </>
        :null } 

        {helpNav ?
          <>
             {/*<button onClick={() => navigate(-1)} className="button close-btn"/>*/}
             <Link to="/"><button className="button close-btn"/></Link>
             <Link to="/contact"><button className="button small-btn contact">Contact</button></Link>
          </>
        :null }
        </div> 

        {seeCalendly ?
          <>
            <motion.div 
              className="modal modalAnimation"
              key="modal"
              variants={ modalAnimation } 
              animate='show' 
              exit='hide'
            >
              
                <button className="button show-hide-chat-btn hide" onClick={hideCalendlyFunction}></button> 
              
              <Programare/>
            </motion.div>

            <motion.div 
              className="modal-overlay quickFadeAnimation"
              key="overaly"
              variants={ quickFadeAnimation } 
              animate='show' 
              exit='hide'
            />
          </>
        :null}  
    </>
  );
}

export default Navigation;
