import React, { useState, useEffect, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion'
import { userInputAnimation, reactionAnimation } from '../components/Animations'


const Bubble = ({ text, delay, isLastBubble, isUserInput, messagesEndRef, hugReaction, heartReaction }) => {
  const [isTyping, setIsTyping] = useState(!isUserInput);
  const [sentTime, setSentTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState('');

  const [showHugReaction, setShowHugReaction] = useState(hugReaction)
  const [showHeartReaction, setShowHeartReaction] = useState(heartReaction)

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setIsTyping(false);
      setSentTime(new Date());
    }, delay);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [delay]);

  useEffect(() => {
    if (sentTime) {
      const timer = setInterval(() => {
        const currentTime = new Date();
        const diffInSeconds = Math.floor((currentTime - sentTime) / 1000);

        if (diffInSeconds < 60) {
          setElapsedTime(`Acum ${diffInSeconds} secunde`);
        } else if (diffInSeconds < 3600) {
          const diffInMinutes = Math.floor(diffInSeconds / 60);
          setElapsedTime(`Acum ${diffInMinutes} minute`);
        } else if (diffInSeconds < 86400) {
          const diffInHours = Math.floor(diffInSeconds / 3600);
          setElapsedTime(`Acum ${diffInHours} ore`);
        } else {
          const diffInDays = Math.floor(diffInSeconds / 86400);
          setElapsedTime(`Acum ${diffInDays} zile`);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [sentTime]);

  return (
    <>
    <div className="testerul" ref={isLastBubble ? messagesEndRef : null}>
    <div className={`chat-bubble ${isUserInput ? 'user-input' : ''}`}>
      {isLastBubble && isTyping && !isUserInput && (
        <>
        <div className="indicator typing">
          <div className="avatar" />
          <span className="typing">Scrie...</span>
        </div>

        </>
      )}
      {!isTyping && !isUserInput && (
        <>
          <div className="booble twin">
            <p className="message">{text}</p>
          </div>
          {isLastBubble && (
            <>
            <div className="indicator status">
              <span className="sent">{sentTime && (elapsedTime || 'Acum')}</span>
            </div>
            </>
          )}
        </>
      )}
      {!isTyping && isUserInput && (
        <>
        <div className="booble twin user-input">
          <p className="message">{text}</p>

          {showHugReaction ?
            <motion.div
              key="reaction"
              className="reaction reactionAnimation"
              variants={ reactionAnimation } 
              animate='show' 
              exit='hide'
            > 
              <div className="hug">👍</div>
            </motion.div>
          :null }

          {showHeartReaction ?
            <motion.div
              key="reaction"
              className="reaction reactionAnimation"
              variants={ reactionAnimation } 
              animate='show' 
              exit='hide'
            > 
              <div className="heart">❤️</div>
            </motion.div>
          :null }

        </div>
        </>
      )}
    </div>
    {/*<div className="bottom-fix" ref={messagesEndRef}/>*/}
    </div>
    </>
  );
};

export default Bubble;
