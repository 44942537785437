import { InlineWidget } from "react-calendly";

function Programare(userReply) {
  const userProblem = userReply.userReply || ""
  // console.log("PROGRAMARE:", userProblem)

  
  return (
    <div className="programare">
      <InlineWidget
        open="true"
        url="https://calendly.com/psihologcool/programare"
        prefill={{ customAnswers: {  a1: userProblem } }}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
}

export default Programare;